import React, { useEffect, useState } from 'react';
import { GridToolbar, GridRowId, GridActionsCellItem, DataGrid, GridColumns, GridRowModel, GridRowParams, GridValidRowModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import useAuthedQuery from '../../hooks/useAuthQuery';
import { Patient } from '../../models/Patient';
import { mainApp } from '../../utils/api'; 
import { useNavigate } from 'react-router-dom';
import { Practitioner } from '../../models/Practitioner';

interface PatientsProps {
    me: Practitioner
}

const NoPatients = () => {
    return (<Box textAlign='center' width='100%' marginTop={2}>Pas de patients</Box>);
};

const PatientsList = (props: PatientsProps) => {
    const [ patients, setPatients ] = useState<Patient[]>([]);

    const { isLoading, error, data, isFetching, refetch } = useAuthedQuery(['patients'], () =>
        mainApp.service("patients").find()
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setPatients(data?.data.map((r: Patient) => ({...r, id: r._id})));
        }
    }, [data]);

    const update = async (newRow: GridRowModel): Promise<GridValidRowModel> => {
        return mainApp.service('patients').patch(newRow._id, newRow);
    };

    const remove = (id: string): Promise<GridValidRowModel> => {
        return mainApp.service('patients').remove(id);
    };

    const handleClick = (id: GridRowId) => () => {
        navigate(id.toString());
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
        if (confirm(`Vous allez supprimer le patient ${id}`)) {
            await remove(id.toString());
            refetch();
        }
    };

    const columns: GridColumns = [
        { field: '_id', headerName: 'ID', width: 200 },
        {
          field: 'firstName',
          headerName: 'Prénom',
          width: 150,
          editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Nom',
            width: 150,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
            editable: false,
        },
        {
            field: 'practitionerCode',
            headerName: 'Code medecin',
            width: 150,
            editable: true,
        },
        {
            field: 'createdAt',
            headerName: 'Date de création',
            width: 150,
            editable: false,
            valueFormatter: ({ value }) => {
                return new Date(value).toLocaleDateString()
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Supprimer',
            width: 50,
            cellClassName: 'actions',
            getActions: (row: any) => {
                if(row.row.pId !== props.me?._id) {
                    return [];
                }
                
                return [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(row.id)}
                    color="inherit"
                  />
                ]
            }
        },
        {
            field: '',
            type: 'actions',
            headerName: 'Ouvrir',
            width: 100,
            cellClassName: 'actions',
            getActions: (row: any) => {
                return [
                  <GridActionsCellItem
                    icon={<KeyboardArrowRightIcon />}
                    label="Open"
                    onClick={handleClick(row.id)}
                    color="inherit"
                  />
                ]
            }
        }
    ];

    return (
        <Box sx={{ height: '90vh', width: '100%' }}>
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            _id: false,
                        },
                    },
                }}
                rows={patients}
                columns={columns}
                loading={isLoading}
                pageSize={20}
                rowsPerPageOptions={[20]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={update}
                components={{
                    NoRowsOverlay: NoPatients,
                    Toolbar: GridToolbar
                }}
            />
            </Box>
    );
};

export default PatientsList;
