import React, { useEffect, useState } from 'react';
import { GridToolbar, GridRowId, DataGrid, GridActionsCellItem, GridRowModel, GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import useAuthedQuery from '../../hooks/useAuthQuery';
import { Practitioner } from '../../models/Practitioner';
import { mainApp } from '../../utils/api'; 
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const PractitionerList = () => {
    const [ updateError, setUpdateError ] = useState<string>();

    const { id } = useParams();

    const { isLoading, error, data: practitionersResponse, isFetching, refetch } = useAuthedQuery([id, 'practitioners'], () =>
        mainApp.service("practitioners").find({ query: { oId: id }})
    );

    const practitioners = practitionersResponse?.data;

    const update = async (newRow: GridRowModel): Promise<GridValidRowModel> => {
        const { password, type, _id, id, createdAt, updatedAt, secretCode, email, isVerified, resetExpires,
        resetShortToken,
        resetToken,
        verifyChanges,
        verifyExpires,
        verifyShortToken,
        verifyToken, oId, ...data} = newRow;
        return mainApp.service('practitioners').patch(newRow._id, data);
    };

    const updateErrorCallback = (err: any) => {
        console.error(updateError);
        setUpdateError(updateError);
    }

    const remove = (id: string): Promise<GridValidRowModel> => {
        return mainApp.service('practitioners').remove(id);
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
        if (confirm(`Vous allez supprimer le PS ${id}`)) {
            try {
                await remove(id.toString());
                refetch();
            } catch(err: any) {
                if(err?.message === 'remaining.patients') {
                    alert('Des patients sont encore attaché à ce praticien, vous devez les transférer avant de le supprimer')
                } else {
                    alert(err.toString());
                }
            }
        }
    };
    
    const columns: GridColumns = [
        { field: '_id', headerName: 'ID', width: 90 },
        {
          field: 'firstName',
          headerName: 'First name',
          width: 150,
          editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            editable: false,
          },
          {
            field: 'secretCode',
            headerName: 'Secret code',
            width: 250,
            editable: true,
        },
        {
            field: 'organisationCode',
            headerName: 'Code organisation',
            width: 250,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Supprimer',
            width: 50,
            cellClassName: 'actions',
            getActions: (row: any) => {
                return [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(row.id)}
                    color="inherit"
                  />
                ]
            }
        }
    ];

    return (
        <Box sx={{ height: '90vh', width: '100%' }}>
            <Typography color="red">
                {error}
                {updateError}
            </Typography>
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            _id: false,
                        },
                    },
                }}
                rows={practitioners?.map((r: Practitioner) => ({...r, id: r._id})) || []}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={update}
                onProcessRowUpdateError={updateErrorCallback}
                getRowId={row => row._id}
                components={{
                    Toolbar: GridToolbar
                }}
            />
            </Box>
    );
};

export default PractitionerList;
