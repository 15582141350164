import React from 'react';
import { Routes, Route } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';

import AdminRoutes from './admin/Routes';
import PractitionerRoutes from './practitioner/Routes';
import PatientRoutes from './patient/Routes';
import Layout from './Layout';
import Home from './Home';

import useAuthedQuery from '../hooks/useAuthQuery';

import { mainApp } from '../utils/api';

const AppRoutes = () => {
    const { isLoading, error, data: me, isFetching } = useAuthedQuery(["me"], () =>
        mainApp.service("me").find()
    , {
        retry: false
    });

    const onIdle = () => {
        if(me) {
            localStorage.removeItem('feathers-jwt');
            window.location.href = '/login';
        }
    }

    useIdleTimer({
        onIdle,
        timeout: 600_000, // 10min
        crossTab: true,
        leaderElection: true,
        syncTimers: 200
    });
    
    return (
        <Layout me={me}>
            <Routes>
                <Route path="/" element={<Home me={me} />} />
                <Route path="pro/*" element={<PractitionerRoutes me={me} />} />
                <Route path="admin/*" element={<AdminRoutes me={me} />} />
                <Route path="patient/*" element={<PatientRoutes me={me} />} />
            </Routes>
        </Layout>
    );
};

export default AppRoutes;
