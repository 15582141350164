import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import authManagementService from 'feathers-authentication-management';

// const socketio = require('@feathersjs/socketio-client');
// const io = require('socket.io-client');

let API_URL = "http://localhost:3030";

if(process.env.NODE_ENV === "production") {
 API_URL = "";
}

const EXPIRE_IN = 86400 * 1000; // 1 day

export type User = {
  type: string
};

function getApp(url: string) {
  const app = feathers();
  // app.configure(authManagementService({}))
  // Connect to a different URL
  const restClient = rest(url);

  // Configure an AJAX library (see below) with that client
  app.configure(restClient.fetch(window.fetch));
  app.configure(
    auth({
      // prefix: 'BEARER',
      storage: localStorage
    })
  );

  return app;
}

export const mainApp = getApp(API_URL);
// const mainSocketApp = getSocketApp(API_URL);

// redirectIfNotAuthenticated();
if(isAuthenticated()) {
  mainApp.reAuthenticate().catch(() => {
    localStorage.removeItem('feathers-jwt');
    window.location.href = '/login';
  });
  // mainSocketApp.authenticate();
}
// authenticate("deshayes.yann@gmail.com", "test");

export async function authenticate(email: string, password: string): Promise<any> {
  let credentials;
  try {
    credentials = await mainApp.authenticate({
      strategy: 'local',
      email,
      password
    });
  } catch(err) {
    console.log(err);
    return err;
  } 
  
  const expireDate = new Date().getTime() + EXPIRE_IN;

  mainApp.set('accessToken', credentials.accessToken);
  mainApp.set('accessTokenExpireDate', expireDate);

  // mainSocketApp.authenticate();

  // window.location = '/';
  return credentials;
}

mainApp.on('reauthentication-error', () => {
  localStorage.removeItem('feathers-jwt');
  window.location.href = '/login';
});

export function isAuthenticated() {
  return localStorage.getItem('feathers-jwt');
}

export function redirectIfNotAuthenticated() {
  if(window.location.pathname.startsWith('/verify') || window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/signup') || window.location.pathname.startsWith('/forgot-password') || window.location.pathname.startsWith('/reset-password')) {
    return false;
  }
  
  if(!isAuthenticated()) {
    localStorage.removeItem('feathers-jwt');
    window.location.pathname = '/login';
  }
}

export default {
  mainApp,
  // mainSocketApp,
  authenticate
};
