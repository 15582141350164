import React from 'react';
import Typography from '@mui/material/Typography';

import { Patient } from '../../models/Patient';
import useAuthedQuery from '../../hooks/useAuthQuery';
import { mainApp } from '../../utils/api';
import { SaveGame } from '../../models/SaveGame';
import { SaveGameInfoCard } from '../practitioner/SaveGameInfoCard';

interface DashboardProps {
    me: Patient
}

const PatientDashboard = (props: DashboardProps) => {
    /*const { isLoading, error, data: gameSessions, isFetching } = useAuthedQuery(['game-sessions'], () =>
        mainApp.service("game-sessions").find()
    );*/

    const { isLoadingSaveGames, errorSaveGames, data: saveGames, isFetchingSaveGames } = useAuthedQuery(['save-games'], () =>
      mainApp.service("save-games").find({query : { $limit: 1, $sort: { updatedAt: -1 } }})
    );

    const saveGame = saveGames?.data?.[0] as SaveGame;

    return (<div>
        <Typography component="h1" variant="h5" marginBottom={5}  marginTop={5}>
                Programme:
        </Typography>
        {
            saveGame?.data ? <SaveGameInfoCard
                chapters={saveGame?.data.chapters}
                evas={saveGame?.data.evas}
            /> : <Typography>Aucune sauvegarde</Typography>
        }
    </div>);
};

export default PatientDashboard;
