import * as React from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';

import logo from '../../images/logo.jpeg';

import { mainApp } from '../../utils/api';

export default function CreateOrganisation() {
  const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        watch
  } = useForm();

  const createAccount = async (data: any) => {
    try {
      await mainApp.service("organisations").create(data);
    } catch(err: any) {
      if(!err.errors || !Object.keys(err.errors).length) {
        setError('email', {
          message: err?.message
        });
      } else {
        Object.keys(err.errors).forEach(k => {
          setError(k, {
            message: err.errors[k]
          });
        });
      }
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} width={100} />
          <Typography component="h1" variant="h5">
            Créer une organisation
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit(createAccount)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Nom"
                  error={!!errors.name?.message}
                  helperText={errors.name?.message as string}
                  {...register('name', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Nombre de licences"
                  error={!!errors.maxPatientNumber?.message}
                  helperText={errors.maxPatientNumber?.message as string}
                  {...register('maxPatientNumber', { required: true, valueAsNumber: true })}
                />
              </Grid>
            </Grid>
            {
            isSubmitSuccessful ? 
              <Grow in={isSubmitSuccessful}><Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Typography variant="h6">Organisation créé avec succès</Typography>
              </Box></Grow>
            :
            isSubmitting ?
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
            : <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Valider
            </Button>}
          </Box>
        </Box>
      </Container>
  );
}