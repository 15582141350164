
import { useQuery } from '@tanstack/react-query';

const useAuthedQuery = (...options: any): any => {
    // @ts-ignore
  const query = useQuery(...options);

  // @ts-ignore
  if (query?.error?.response?.status === 401) {
    window.location.href = '/login';
  }
  return query;
}

export default useAuthedQuery;