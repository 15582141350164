import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import { GeneralError } from "../models/Errors";
import { mainApp } from '../utils/api'; 

export default function Verify() {
  const [error, setError] = useState<string>();

  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    const checkToken = async () => {
      const token = searchParams.get("token");

      try {
        await mainApp.service("auth-management").create({ action: 'verifySignupLong',
          value: token, // compares to .verifyToken
        });
        window.location.href = "/login";
      } catch(err) {
        console.log(err);
        if((err as GeneralError).code === 400) {
          setError('Lien expiré');
        }

        setError((err as GeneralError).message);
      }
    };

    checkToken();
  }, []);

  return (<div>
    {error}
  </div>);
}
