import React, { useState } from 'react';
import { useLocation } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import api from "../utils/api";
import { useTheme } from '@mui/material/styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [sent, setsent] = useState(false);

  const theme = useTheme();
  let query = useQuery();

  const resetPassword = async () => {
    setLoading(true);
    const token = query.get("token");
    if(token) {
      await api.mainApp.service("auth-management").create({ action: 'resetPwdLong',
        value: {
          token, // compares to .resetToken
          password, // new password
        },
      })
      setsent(true);
      window.location.href = "/login";
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Avatar style={{
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
        }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
        Reinitialiser le mot de passe
        </Typography>
        <form className={{
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(3),
        }} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="password"
                label={"Nouveau mot de passe"}
                name="password"
                value={password}
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
            {sent ? <Typography component="body2" variant="body2">Cochez la case</Typography> : <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              style={{
                margin: theme.spacing(3, 0, 2),
              }}
              onClick={resetPassword}
            >
              Reinitialiser mon mot de passe
            </Button>}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                 Je me souviens de mon mot de passe
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}