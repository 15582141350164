import React, { useEffect, useState } from 'react';
import { GridRowId, GridToolbar, DataGrid, GridColumns, GridRowModel, GridActionsCellItem, GridValidRowModel, GridCellParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useAuthedQuery from '../../hooks/useAuthQuery';
import { mainApp } from '../../utils/api'; 
import { Typography } from '@mui/material';
import { Organisation } from '../../models/Organisation';
import { Link } from 'react-router-dom';

const OrganisationsList = () => {
    const [ organisations, setOrganisations ] = useState<Organisation[]>([]);
    const [ error, setError ] = useState<string>();

    const { refetch } = useAuthedQuery(['organisations'], () =>
        mainApp.service("organisations").find()
    );

    const navigate = useNavigate();

    useEffect(() => {
        const loadOrganisations = async () => {
            const response = await mainApp.service('organisations').find();
            setOrganisations(response.data);
        };

        loadOrganisations();
    }, []);

    const update = async (newRow: GridRowModel): Promise<GridValidRowModel> => {
        const { _id, secretCode, ...data} = newRow;
        return mainApp.service('organisations').patch(newRow._id, data);
    };

    const remove = (id: string): Promise<GridValidRowModel> => {
        return mainApp.service('organisations').remove(id);
    };

    const updateError = (err: any) => {
        console.error(err);
        setError(err);
    }

    const handleClick = (id: GridRowId) => () => {
        navigate(id.toString());
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
        if (confirm(`Vous allez supprimer l'organisation ${id}`)) {
            await remove(id.toString());
            refetch();
        }
    };

    const columns: GridColumns = [
        { field: '_id', headerName: 'ID', width: 90 },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
          editable: true,
        },
        {
            field: 'isLicenceActive',
            headerName: 'Is licence active',
            type: 'boolean',
            width: 150,
            editable: true,
        },
        {
            field: 'maxPatientNumber',
            headerName: 'Patient max number',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            field: 'secretCode',
            headerName: 'Code',
            type: 'string',
            width: 150,
            editable: false,
        },
        {
            field: 'games',
            headerName: 'Games',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Supprimer',
            width: 50,
            cellClassName: 'actions',
            getActions: (row: any) => {
                return [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(row.id)}
                    color="inherit"
                  />
                ]
            }
        },
        {
            field: '',
            type: 'actions',
            headerName: 'Ouvrir',
            width: 100,
            cellClassName: 'actions',
            getActions: (row: any) => {
                return [
                  <GridActionsCellItem
                    icon={<KeyboardArrowRightIcon />}
                    label="Open"
                    onClick={handleClick(row.id)}
                    color="inherit"
                  />
                ]
            }
        }
    ]

    return (
        <Box sx={{ height: '90vh', width: '100%' }}>
            <Typography color="red">
                {error}
            </Typography>
            <Link to="/admin/organisations/new">Créer une organisation</Link>
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            _id: false,
                        },
                    },
                }}
                rows={organisations}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={update}
                onProcessRowUpdateError={updateError}
                getRowId={row => row._id}
                components={{
                    Toolbar: GridToolbar
                }}
            />
            </Box>
    );
};

export default OrganisationsList;
