import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Patient } from '../../models/Patient';

import Profile from './Profile';

interface RoutesProps {
    me: Patient
}

const PatientRoutes = (props: RoutesProps) => {
    return (
        <Routes>
            <Route path="/profile" element={<Profile me={props.me} />} />
        </Routes>
    );
};

export default PatientRoutes;
