import React, { useEffect, useState } from 'react';

import PractitionerDashboard from './practitioner/Dashboard';
import AdminDashboard from './admin/Dashboard';
import PatientDashboard from './patient/Dashboard';

import { User, UserType } from '../models/User';

interface HomeProps {
    me: User
}

export default function Home(props: HomeProps) {
    if(!props.me) {
        return null;
    }

    let Dashboard: any;

    if(props.me.type === UserType.Practitioner) {
        Dashboard = PractitionerDashboard;
    }

    if(props.me.type === UserType.Admin) {
        Dashboard = AdminDashboard;
    }

    if(props.me.type === UserType.Patient) {
        Dashboard = PatientDashboard;
    }

    return (<>
        <Dashboard me={props.me} />
    </>);
}
