import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { Practitioner } from '../../models/Practitioner';
import useAuthedQuery from '../../hooks/useAuthQuery';

import { mainApp } from '../../utils/api';
import { Organisation } from '../../models/Organisation';

interface ProfileProps {
    me: Practitioner
}

const PractitionerProfile = (props: ProfileProps) => {
  const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        watch
  } = useForm();

  // Get the organisation to get the licence status
  const { isLoading, error, data: organisation, isFetching } = useAuthedQuery(['organisation', props.me?.oId], () =>
  mainApp.service("organisations").get(props.me?.oId)
);

  const theme = useTheme();

  useEffect(() => {
    if(props.me) {
        Object.keys(props.me).forEach((m: string) => {
             if(m !== 'password') {
                // @ts-ignore
                setValue(m, props.me[m]);
             }
        });
    }
  }, [props.me]);

  const updateAccount = async (data: any) => {
    try {
      await mainApp.service("practitioners").patch(props.me._id, data);
    } catch(err: any) {
      if(!err.errors || !Object.keys(err.errors).length) {
        setError('email', {
          message: err?.message
        });
      } else {
        Object.keys(err.errors).forEach(k => {
          setError(k, {
            message: err.errors[k]
          });
        });
      }
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Mon profil
          </Typography>
          <Box padding={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableBody>
                <TableRow
                  key='licence'
                >
                <TableCell component="th" scope="row">
                  Licence:
                </TableCell>
                <TableCell component="th" scope="row">
                    {organisation?.isLicenceActive ?
                    <Typography color={theme.palette.success.light}>Active</Typography>
                    : <Typography color={theme.palette.error.light}>Inactive</Typography>
                  }
                </TableCell>
                </TableRow>
                <TableRow
                  key='code'
                >
                <TableCell component="th" scope="row">
                  Code à transmettre à mes patients:
                </TableCell>
                <TableCell component="th" scope="row">
                  {props.me.secretCode}
                </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>
          </Box>
          <Typography component="h1" variant="h5">
            Edition
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit(updateAccount)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Nom"
                  autoComplete="family-name"
                  error={!!errors.lastName?.message}
                  helperText={errors.lastName?.message as string}
                  {...register('lastName', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  required
                  fullWidth
                  label="Prénom"
                  error={!!errors.firstName?.message}
                  helperText={errors.firstName?.message as string}
                  {...register('firstName', { required: true })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Adresse de facturation"
                  autoComplete="address"
                  error={!!errors.address?.message}
                  helperText={errors.address?.message as string}
                  {...register('address', { required: true })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Ville"
                  error={!!errors.city?.message}
                  helperText={errors.city?.message as string}
                  {...register('city', { required: true })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Code postale"
                  error={!!errors.cp?.message}
                  helperText={errors.cp?.message as string}
                  {...register('cp', { required: true })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SIRET"
                  error={!!errors.siret?.message}
                  helperText={errors.siret?.message as string}
                  {...register('siret', {})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled
                  label="Email"
                  autoComplete="email"
                  error={!!errors.email?.message}
                  helperText={errors.email?.message as string}
                  {...register('email', {
                    required: true,
                    minLength: {
                      value: 4,
                      message: 'L\'email doit contenir au moins 4 caractères'
                    },
                    maxLength: {
                      value: 50,
                      message: 'L\'email ne doit pas dépasser 50 caractères'
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Link href="/forgot-password" variant="body2">
                  Changer le mot de passe
                </Link>
              </Grid>
            </Grid>
            {
            isSubmitSuccessful ? 
              <Grow in={isSubmitSuccessful}><Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Typography variant="h6">Votre compte a été mis à jour avec succès</Typography>
              </Box></Grow>
            :
            isSubmitting ?
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
            : <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Valider
            </Button>}
          </Box>
        </Box>
      </Container>);
};

export default PractitionerProfile;
