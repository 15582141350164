import React from 'react';
import { Practitioner } from '../../models/Practitioner';

import useAuthedQuery from '../../hooks/useAuthQuery';
import { mainApp } from '../../utils/api'; 
import BasicCard from '../../components/InformationCard';

interface DashboardProps {
    me: Practitioner
}

const PractitionerDashboard = (props: DashboardProps) => {
    const { isLoading, error, data, isFetching } = useAuthedQuery(['patients'], () =>
        mainApp.service("patients").find()
    );

    return (<div>
        <h1>Tableau de bord professionel</h1>
        <BasicCard primaryText={data?.total} title='Patient(s)' />
    </div>);
};

export default PractitionerDashboard;
