import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PractitionerDrawer from './practitioner/Drawer';
import PatientDrawer from './patient/Drawer';
import AdminDrawer from './admin/Drawer';

import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';

import { User, UserType } from '../models/User';

const DRAWER_WIDTH = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isDesktop' })<{
    open?: boolean;
    isDesktop: boolean;
  }>(({ theme, open, isDesktop }) => ({
    flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(isDesktop && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 200,
  }),
  }));

export default function Layout(props: { children: JSX.Element, me: User }) {
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);

    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    if(!props.me) {
        return null;
    }

    let CustomDrawer: any;

    if(props.me.type === UserType.Practitioner) {
      CustomDrawer = PractitionerDrawer;
    }

    if(props.me.type === UserType.Admin) {
      CustomDrawer = AdminDrawer;
    }

    if(props.me.type === UserType.Patient) {
      CustomDrawer = PatientDrawer;
    }
    

    return (<>
        <Drawer width={DRAWER_WIDTH} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}>
            <>
                <Toolbar />
                <Divider />
                <CustomDrawer />
            </>
        </Drawer>
        <Main open={mobileOpen} isDesktop={isDesktop}>
        <AppBar handleDrawerToggle={handleDrawerToggle} me={props.me} drawerOpen={mobileOpen} isDesktop={isDesktop} drawerWidth={DRAWER_WIDTH} />
        <Container component="main" maxWidth='xl'>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: 1 }}
            >
                {props.children}
            </Box>
        </Container>
        </Main>
    </>);
}
