import React from 'react';
import { Routes, Route } from "react-router-dom";

import PractitionersList from './PractitionersList';
import OrganisationsList from './OrganisationsList';
import CreateOrganisation from './CreateOrganisation';
import { User } from '../../models/User';


const AdminRoutes = ( { me }: { me: User }) => {
    if (!me.isAdmin) {
        window.location.href = '/';
        return null;
    }
    return (
        <Routes>
            <Route path="/organisations" element={<OrganisationsList />} />
            <Route path="/organisations/new" element={<CreateOrganisation />} />
            <Route path="/organisations/:id" element={<PractitionersList />} />
        </Routes>
    );
};

export default AdminRoutes;
