import React from 'react';
import Drawer from '@mui/material/Drawer';

import logo from '../images/logo.jpeg';
import { Theme } from '@mui/material';

interface ResponsiveDrawerProps {
    children: JSX.Element,
    width: number,
    mobileOpen: boolean,
    handleDrawerToggle: () => void
}

export const getStyle = (selected: boolean, theme: Theme) => {
  if(selected) {
    return {
      backgroundColor: theme.palette.primary.light,
      color: 'white'
    };
  }

  return undefined;
};

const ResponsiveDrawer = (props: ResponsiveDrawerProps) => {
    return (
        <>
        <Drawer
          variant="temporary"
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.width },
          }}
        >
          <div style={{  
            position: 'absolute',
            display: 'flex',
            alignSelf: 'center',
            marginTop: 5,
            justifySelf: 'center', 
            top: 0
          }}>
            <img src={logo} width={96/1.5} height={63/1.5} />
          </div>
          {props.children}
          <div style={{
            display: 'flex',
            alignSelf: 'center',
            position: 'absolute',
            bottom: 0
          }}>
            Version 1.0.0
          </div>
        </Drawer>
        <Drawer
          variant="persistent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.width },
          }}
          anchor="left"
          open
        >
          <div style={{  
            position: 'absolute',
            display: 'flex',
            alignSelf: 'center',  
            top: 0
          }}>
            <img src={logo} width={96} height={63} />
          </div>
          {props.children}
          <div style={{
            display: 'flex',
            alignSelf: 'center',  
            position: 'absolute',
            bottom: 0
          }}>
            Version 1.0.0
          </div>
        </Drawer>
        </>
    );
};

export default ResponsiveDrawer;
