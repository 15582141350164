import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

import { User, UserType } from '../models/User';
import { Patient } from '../models/Patient';
import { Practitioner } from '../models/Practitioner';
import { mainApp } from '../utils/api';

interface UserAppBarProps {
    me: User | Patient | Practitioner
}

const UserAppBar = (props: UserAppBarProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const goToProfile = () => {
    setAnchorEl(null);

    let URLPrefix = 'admin/';
    if(props.me.type === UserType.Patient) {
      URLPrefix = 'patient/'
    } else if(props.me.type === UserType.Practitioner) {
      URLPrefix = 'pro/'
    }
    navigate(`${URLPrefix}profile`);
  };

  const goToLogout = () => {
    mainApp.logout();
    navigate('/login');
  };

  if(!props.me) {
      return null;
  }

  return (<div style={{ display: 'flex', alignItems: 'center'}}>
      {props.me?.firstName} {props.me?.lastName}
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={goToProfile}>Profil</MenuItem>
        <MenuItem onClick={goToLogout}>Déconnexion</MenuItem>
      </Menu>
    </div>);
}

export default UserAppBar;
