import * as React from 'react';
import { Routes, Route } from "react-router-dom";

import Login from './pages/Login';
import PractitionerSignup from './pages/practitioner/Signup';
import PatientSignup from './pages/patient/Signup';
import AppRoutes from './pages/Routes';
import Verify from './pages/Verify';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

export default function App() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="verify" element={<Verify />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="pro/signup" element={<PractitionerSignup />} />
      <Route path="patient/signup" element={<PatientSignup />} />
      <Route path="*" element={<AppRoutes />} />
    </Routes>
  );
}
