import React, { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import api from "../utils/api";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [sent, setsent] = useState(false);

  const theme = useTheme();
  
  const resetPassword = async () => {
    setLoading(true);
    await api.mainApp.service("auth-management").create({
      action: 'sendResetPwd',
      value: { email }
    });
    setsent(true);
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
        <Avatar sx={{
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
        }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
        Reinitialiser le mot de passe
        </Typography>
        <form style={{
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(3),
        }} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
            {sent ? <Typography component="body2" variant="body2">Un email vient de vous être envoyé, cliquez sur le lien pour changer le mot de passe</Typography> : <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              style={{submit: {
                margin: theme.spacing(3, 0, 2),
              }}}
              onClick={resetPassword}
            >
              Reinitialiser mon mot de passe
            </Button>}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                 Je me souviens de mon mot de passe
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}