import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import UserAppBar from './UserAppBar';
import { User } from '../models/User';
import { Patient } from '../models/Patient';
import { Practitioner } from '../models/Practitioner';

interface BarProps extends MuiAppBarProps {
    handleDrawerToggle: () => void,
    me: User | Patient | Practitioner,
    drawerWidth: number,
    drawerOpen: boolean,
    isDesktop: boolean
}

const AppBar = (props: BarProps) => {
    return (
        <MuiAppBar position="static" {...props}>
            <Toolbar variant="dense">
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, display: { xs: 'block', sm: 'none' }, }}
                    onClick={props.handleDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                </Typography>
                <UserAppBar me={props.me} />
            </Toolbar>
        </MuiAppBar>
    );
};

export default AppBar;
