import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStyle } from '../../components/Drawer';
import { useTheme } from '@mui/material';

const PatientDrawer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    return (
        <List>
            <ListItem key='accueil' disablePadding sx={getStyle(location.pathname === '/', theme)}>
                <ListItemButton onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <HomeIcon sx={getStyle(location.pathname === '/', theme)} />
                    </ListItemIcon>
                    <ListItemText primary='Accueil' />
                </ListItemButton>
            </ListItem>
            <ListItem key='documents' disablePadding>
                <ListItemButton href="https://vertexa.fr/Interface_Patients/" target="_blank">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='Mes documents et tutoriels' />
                </ListItemButton>
            </ListItem>
        </List>
    );
};

export default PatientDrawer;
