const stageToNameMapping = [
    "Remédiation cognitive (boxe)",
    "HUB",
    "Tutoriel",
    "Connexion",
    "Quizz alimentaire",
    "Gestion de l’hyperactivité (Yoga)",
    "Pêche aux aliments",
    "Remédiation cognitive (baseball)",
    "Portes aux formes",
    "Modelage de silhouette",
    "Evaluation",
    "Diversité Alimentaire",
    "Complications des TCA",
    "Illusion des contenants",
    "Motivation au changement",
    "Historique et Étiologies des TCA",
    "Estime de soi et des proches",
    "Accomplissements",
    "Historique, poids & comportement alimentaire",
    "Restriction cognitive et croyances alimentaires",
    "Entrainons-nous",
    "Balance décisionnelle",
    "Sensations alimentaires ",
    "Autoévaluation du comportement alimentaire",
    "Manger ses émotions / Gestion émotionnelle",
    "Insatisfaction corporelle",
    "NONE"
];

export const getLevelName = (level:number): string =>{
  if(level == 1) return "niveau 1";
  if(level == 2) return "niveau 2";
  if(level == 4) return "niveau 3";
  if(level == 8) return "niveau 4";
  if(level == 16) return "niveau 5";
  if(level == 32) return "niveau 6";
  if(level == 64) return "niveau 7";
  if(level == 128) return "niveau 8";
  return "";
}

export const getStageName = (stageId: number): string => {
    return stageToNameMapping[stageId];
}

export const getWorkshopInVivo = (workshop: any): boolean => {
  if (
    (workshop.type == 22 && workshop.level == 1) ||
    (workshop.type == 22 && workshop.level == 2) ||
    (workshop.type == 22 && workshop.level == 8) ||
    (workshop.type == 23 && workshop.level == 4) ||
    (workshop.type == 24 && workshop.level == 2) ||
    (workshop.type == 19 && workshop.level == 4) ||
    (workshop.type == 25 && workshop.level == 4)
  ) {
    return true;
  }
  return false;
};