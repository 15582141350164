import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getStageName } from '../../utils/mapping';
import { getWorkshopInVivo } from '../../utils/mapping';
import { getLevelName } from '../../utils/mapping';
import CircularProgress, {CircularProgressProps} from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type Workshop = {
  type: number,
  level: number,
  score: number,
  IsDone: boolean,
  duration: number
};

type Stage = {
  id: string,
  IsDone: boolean,
  workshops: Workshop[]
};

type Chapter = {
  id: string,
  name: string,
  IsDone: boolean,
  stages: Stage[]
};

type EVA = {
  date: string,
  values: number[]
};

export interface StageInfoCardProps {
    chapters: Chapter[],
    evas: EVA[]
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2)
}));

function GetChapterDuration(chapter: Chapter) {
  var seconds = 0;
  chapter?.stages.forEach(function (stage) {
    stage?.workshops.forEach(function (workshop) {
      seconds += workshop.duration;
    });
  });
  return new Date(seconds * 1000).toISOString().substring(14, 19);
}

function GetChapterCompletion(chapter: Chapter) {
  var countDone = 0;
  var countTotal = 0;
  chapter?.stages.forEach(function (stage) {
    stage?.workshops.forEach(function (workshop) {
      countTotal +=1;
      if(workshop.IsDone)
        countDone +=1;
    });
  });
  return countDone/countTotal*100;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const SaveGameInfoCard = ({
    chapters,
    evas
}: StageInfoCardProps) => {
    return (
    <Card sx={{marginBottom: 5 }}>
      {chapters?.map((chapter) => (
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Item>
              <CircularProgressWithLabel
                value={GetChapterCompletion(chapter)}
              />
              <div>{GetChapterDuration(chapter)}</div>
            </Item>
            <Grid item xs={10}>
              {chapter.IsDone ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              Chapitre : {chapter.id}
              {chapter.stages?.map((stage) => (
                <ol>
                  <div>
                    {stage.IsDone ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                    Stage : {stage.id}
                  </div>
                  {stage.workshops?.map((workshop) => (
                    <ol>
                      {workshop.IsDone ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                      {getStageName(workshop.type)} (
                      {getLevelName(workshop.level)}) :&nbsp;
                      {new Date(workshop.duration * 1000)
                        .toISOString()
                        .substring(14, 19)}
                      &nbsp;- ({workshop.score}%) :&nbsp;
                      {workshop.IsDone && getWorkshopInVivo(workshop) ? (
                        <a
                          href={
                            "https://vertexa.fr/InVivo_" +
                            workshop.type +
                            "_" +
                            workshop.level +
                            ".pdf"
                          }
                        >
                          Application in vivo
                        </a>
                      ) : (
                        ""
                      )}
                    </ol>
                  ))}
                </ol>
              ))}
            </Grid>
          </Grid>
        </Stack>
      ))}
    </Card>
    );
};
