export enum UserType {
    Admin,
    Patient,
    Practitioner,
}

export type User = {
    _id: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    pId: string,
    type: UserType,
    isAdmin: boolean
};